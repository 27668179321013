import { Row, Col } from 'react-bootstrap';
import PageHeader from '../components/PageHeader';
import LastConnectedClientsWidget from './Widgets/LastConnectedClientsWidget';
import DropdownTerminalSelector from "./../components/DropdownTerminalSelector";
import { useState, useEffect } from 'react';
import {
  GetTerminals
} from '../IoTDevices/clients';

const DashboardPage = () => {
  const [selectedTerminals, setSelectedTerminals] = useState([]);
  const [allTerminals, setAllTerminals] = useState();

  useEffect(() => {
    GetTerminals()
      .then((terminals) => {
        setAllTerminals(terminals);
      })
      .catch((err) => {
        // If no terminals came back from API, we assume an error occurred.
        setAllTerminals(undefined);
        console.error(err);
      });
  }, []);

  return (
    <>
      <PageHeader title='Dashboard' />
      <Row className='m-3' xs={1}>
        <Col className='mb-3'>
          {allTerminals && (
            <div className="col-12 col-md-6 col-lg-3 col-sm-6 mt-md-0 mt-2">
              <DropdownTerminalSelector
                options={allTerminals}
                onSaveSelection={setSelectedTerminals}
                initialValues={selectedTerminals}
              ></DropdownTerminalSelector>
            </div>
          )}
        </Col>
        <Row>
          <Col className='mb-3 col-12 col-xl-6'>
            <LastConnectedClientsWidget
              selectedTerminals={selectedTerminals}
              onChangeTerminals={setSelectedTerminals}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default DashboardPage;
